import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ActivateLoyaltyCard from './ActivateLoyaltyCard';
import GivexLoyaltyContainer from '../GivexLoyaltyModal/GivexLoyaltyContainer';

const Wrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  max-width: 660px;
  display: grid;
  grid-gap: 40px;
  background: white;
  margin: auto;
  @media (max-width: 786px) {
    padding: 24px 20px;
  }
`;

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
  },
});

const ActivateLoyaltyCardContainer = ({classes}) => {
  const [givexLoyaltyModalIsVisible, setGivexLoyaltyModalIsVisible] = useState(
    false
  );

  return (
    <Fragment>
      <ActivateLoyaltyCard
        setGivexLoyaltyModalIsVisible={setGivexLoyaltyModalIsVisible}
      />
      <Dialog
        id="Loyalty-ActivateLoyalty"
        aria-labelledby="payment-methods"
        aria-describedby="payment-methods"
        open={givexLoyaltyModalIsVisible}
        onBackdropClick={() => setGivexLoyaltyModalIsVisible(false)}
        fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
        fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
        classes={{scrollPaper: classes.scrollPaper}}
        PaperProps={{classes: {root: classes.paper}}}
        scroll="body"
      >
        <Wrapper>
          <InnerWrapper>
            <GivexLoyaltyContainer
              shouldDisplayTitle={true}
              hideAuthenticationModal={() =>
                setGivexLoyaltyModalIsVisible(false)
              }
            />
          </InnerWrapper>
        </Wrapper>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(ActivateLoyaltyCardContainer);
