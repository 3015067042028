import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import HomeA from './Home__a/Home';
import HomeB from './Home__b/Home';
import appConfig from '../../../app-config';

const HomeContainer = ({ authReducer, customerProfile, loyalty }) => {
  const { isAuthenticated } = authReducer;

  const [innerWidth, setInnerWidth] = useState(null);

  useEffect(() => {
    const windowSizeHandler = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', windowSizeHandler);
    return () => window.removeEventListener('resize', windowSizeHandler);
  }, []);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [typeof window !== 'undefined' && window.innerWidth]);

  const navigateToMenu = () => {
    const deepLink = localStorage.getItem('deep-link-url');
    if (deepLink) {
      navigate('/' + deepLink);
    } else {
      navigate('/');
    }
  };
  if (innerWidth && innerWidth > 1024) {
    return (
      <HomeB
        id="Home-HomeB"
        customerProfile={customerProfile}
        isAuthenticated={isAuthenticated}
        navigateToMenu={navigateToMenu}
        loyalty={loyalty}
      />
    );
  } else {
    return (
      <HomeA
        id="Home-HomeA"
        customerProfile={customerProfile}
        isAuthenticated={isAuthenticated}
        navigateToMenu={navigateToMenu}
        loyalty={loyalty}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loyalty: state.loyaltyReducer.loyalty,
  customerProfile: state.profileSettingsReducer.customerProfile,
});

export default connect(mapStateToProps)(HomeContainer);
