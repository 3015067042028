import React, {Fragment} from 'react';
import styled from 'styled-components';
import ImageFadeIn from 'react-image-fade-in';
import {Title1, Caption1, Subhead} from '../../../components/Typography';
import {navigate} from 'gatsby';

const Wrapper = styled.div`
  height: 240px;
  // padding-top: 75%;
  // width: 100%;
  max-width: 560px;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  background: ${props =>
    props.noImage
      ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent)'
      : '#fff'};
  display: grid;
  cursor: pointer;
  grid-template-columns: 240px 320px;
  align-items: center;
  &:hover {
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.18);
    transition: all 0.25s ease;
  }
  @media (max-width: 786px) {
    height: 100%;
    margin: 0;
    max-width: unset;
    width: unset;
    grid-template-columns: 1fr;
    grid-template-rows: 240px 1fr;
    // max-width: 560px;
  }
`;

const Image = styled(ImageFadeIn)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  @media (max-width: 786px) {
    height: initial;
  }
`;

const InfoWrapper = styled.div`
  padding: 24px 48px;
  // width: 384px;
  position: relative;
  // text-align: center;
  margin: auto auto auto 0;
  @media (max-width: 786px) {
    padding: 11.5px 16px;
    background: #fff;
    margin: 0;
  }
`;

const SingleCard = ({feedRow}) => {
  const item = feedRow.items[0];
  let background_image_url;
  let title;
  let subtitle;
  let description;
  if (item) {
    background_image_url = item.background_image_url;
    title = item.title;
    subtitle = item.subtitle;
    description = item.description;
  }

  const cardAction = () => {
    const {card_action} = feedRow.items[0];
    if (feedRow.feed_type === 'all_products') {
      navigate('/');
    } else if (feedRow.feed_type === 'all_businesses') {
      navigate('/locations');
    } else if (card_action && card_action.includes('://products/')) {
      const productId = card_action.substring(card_action.lastIndexOf('/') + 1);
      navigate(`/menu/${productId}`);
    }
  };

  return (
    <Wrapper onClick={cardAction} noImage={!background_image_url}>
      <Image src={background_image_url} />

      <InfoWrapper>
        <div style={{margin: 'auto'}}>
          <Subhead id="HomeFeed-SingleCard-Subhead">{subtitle}</Subhead>
          <Title1 id="HomeFeed-SingleCard-Title" style={{marginTop: '3px'}}>{title}</Title1>
          <div style={{marginTop: '20px'}}>
            <div>{description}</div>
            <div style={{position: 'absolute', left: 48, bottom: 24}} />
          </div>
        </div>
      </InfoWrapper>
    </Wrapper>
  );
};

export default SingleCard;
