import React from 'react';
import styled from 'styled-components';
import SingleCard from './components/SingleCard';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 48px;
  align-items: center;
  margin-bottom: 48px;
  @media (max-width: 786px) {
    grid-gap: 48px;
    margin-bottom: 8px;
  }
`;

const HomeFeed = ({homeFeed}) => {
  return (
    <div>
      <div style={{margin: '0 auto', maxWidth: '560px'}}>
        <Wrapper>
          {homeFeed.map(feedRow => {
            if (feedRow.feed_style_type === 'single_card') {
              return <SingleCard id="HomeFeed-SingleCard" feedRow={feedRow} key={feedRow.feed_id} />;
            }
          })}
        </Wrapper>
      </div>
    </div>
  );
};

export default HomeFeed;
