import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Title1} from '../../../components/Typography';
import HomeFeedContainer from '../../HomeFeed/HomeFeedContainer';
import ImageFadeIn from 'react-image-fade-in';
import heroImages from '../../../assets/heroImages';
import appConfig from '../../../../app-config';
import OrderSettingSummaryContainer from '../../OrderSetting/OrderSettingSummary/OrderSettingSummaryContainer';
import OrderHistoryContainer from '../../OrderHistory/OrderHistoryContainer';
import GiveXLoyaltyCardContainer from '../../Loyalty/GiveXLoyaltyCard/GiveXLoyaltyCardContainer';
import ActivateLoyaltyCardContainer from '../../Loyalty/ActivateLoyaltyCard/ActivateLoyaltyCardContainer';
import InternalLoyaltyCardContainer from '../../Loyalty/InternalLoyaltyCard/InternalLoyaltyCardContainer';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 64px;
  max-width: 714px;
  padding: 0 20px;
  margin: auto;
  margin-top: 48px;
  @media (max-width: 786px) {
    margin-top: 32px;
    padding: 0 20px;
    max-width: unset;
    margin: 0;
    margin-top: 48px;
  }
`;

const HeroWrapper = styled.div`
  position: relative;
`;

const HeroImage = styled(ImageFadeIn)`
  width: 100vw;
  min-height: 400px;
  max-height: 80vh;
  object-fit: cover;
  @media (max-width: 786px) {
    min-height: 440px;
  }
`;

const EditOrderSettingWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 362px;
  min-width: 362px;

  @media (max-width: 786px) {
    padding: 0 20px;
  }
`;

const WelcomeMessage = styled(Title1)`
  color: ${props => props.theme.black};
`;

const Home = ({customerProfile, isAuthenticated, loyalty}) => {
  return (
    <div>
      <HeroWrapper>
        <HeroImage src={heroImages[appConfig.appSource]} alt="hero image" />
        <div style={{maxWidth: '740px', margin: 'auto'}}>
          <EditOrderSettingWrapper>
            <OrderSettingSummaryContainer isHomeOrderConfig />
          </EditOrderSettingWrapper>
        </div>
      </HeroWrapper>
      <Wrapper>
        {isAuthenticated && (
          <WelcomeMessage id="HomeA-Message">
            Welcome Back, {customerProfile && customerProfile.first_name}
          </WelcomeMessage>
        )}
        {isAuthenticated ? (
          loyalty ? (
            appConfig.loyaltyType === 'givex' ? (
              <Fragment>
                {loyalty.loyalty_details.details.data.status ===
                'REGISTERED' ? (
                  <GiveXLoyaltyCardContainer id="HomeA-GiveXLoyaltyCardContainer" />
                ) : (
                  <ActivateLoyaltyCardContainer id="HomeA-ActivateLoyaltyCardContainer" />
                )}
              </Fragment>
            ) : (
              <InternalLoyaltyCardContainer id="HomeA-InternalLoyaltyCardContainer" />
            )
          ) : null
        ) : null}
        <HomeFeedContainer id="HomeA-HomeFeedContainer" />
        {isAuthenticated && <OrderHistoryContainer hideIfNoOrders />}
      </Wrapper>
    </div>
  );
};

export default Home;
