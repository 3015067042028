import React from 'react';
import {connect} from 'react-redux';
import HomeFeed from './HomeFeed';

const HomeFeedContainer = ({homeFeed}) => (
  <div id="HomeFeed-HomeFeedContainerDiv">{homeFeed && <HomeFeed homeFeed={homeFeed} />}</div>
);

const mapStateToProps = state => ({
  homeFeed: state.homeFeedReducer.homeFeed,
});

export default connect(mapStateToProps)(HomeFeedContainer);
