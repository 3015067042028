import React, { Suspense, lazy } from 'react';
import HomeContainer from '../modules/Home/HomeContainer';
import appConfig from '../../app-config';
// import MenuContainer from '../modules/Menu/MenuContainer';
import { Redirect } from '@reach/router';
import spinner from '../assets/spinner.svg';
const MenuContainer = lazy(() => import('../modules/Menu/MenuContainer'));
const environment = process.env.GATSBY_ENV;

const HomePage = (props) => {
  const isStorageCleared = localStorage.getItem('isStorageCleared');
  if (!isStorageCleared) {
    // localStorage.clear();
    // sessionStorage.clear();
    window.location.reload();
    localStorage.setItem('isStorageCleared', true);
  }
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              marginTop: '160px',
            }}
          >
            <img src={spinner} style={{ width: '100px' }} />
          </div>
        }
      >
        <MenuContainer />
      </Suspense>
    </>
  );
};

export default HomePage;
