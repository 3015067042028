import React from 'react';
import styled from 'styled-components';
import {Caption1, Title2} from '../../../components/Typography';

const Wrapper = styled.div`
  border-radius: 16px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
  background-color: ${props => props.theme.white};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 32px;
  justify-content: space-between;
  padding: 24px 32px;
  align-items: center;
  max-width: 560px;
  width: 560px;
  margin: auto;
`;

const TextWrapper = styled.div`
  display: grid;
  grid-gap: 9px;
`;

const Icon = styled.div`
  font-size: 29px;
`;

const ActivateLoyaltyCard = ({setGivexLoyaltyModalIsVisible}) => {
  return (
    <Wrapper onClick={() => setGivexLoyaltyModalIsVisible(true)}>
      <TextWrapper>
        <Caption1 id="LoyaltyCard-Activate">Activate Your Rewards</Caption1>
        <Title2 id="LoyaltyCard-Create">Create a Code and Start Earning Points</Title2>
      </TextWrapper>

      <Icon className="fas fa-credit-card" />
    </Wrapper>
  );
};

export default ActivateLoyaltyCard;
