import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Title1} from '../../../components/Typography';
import HomeFeedContainer from '../../HomeFeed/HomeFeedContainer';
import ImageFadeIn from 'react-image-fade-in';
import heroImagesMobile from '../../../assets/heroImagesMobile';
import appConfig from '../../../../app-config';
import OrderSettingSummaryContainer from '../../OrderSetting/OrderSettingSummary/OrderSettingSummaryContainer';
import OrderHistoryContainer from '../../OrderHistory/OrderHistoryContainer';
import GiveXLoyaltyCardContainer from '../../Loyalty/GiveXLoyaltyCard/GiveXLoyaltyCardContainer';
import ActivateLoyaltyCardContainer from '../../Loyalty/ActivateLoyaltyCard/ActivateLoyaltyCardContainer';
import InternalLoyaltyCardContainer from '../../Loyalty/InternalLoyaltyCard/InternalLoyaltyCardContainer';

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 2fr 3fr;
`;

const C = styled.div`
  // height: calc(100vh - 150px);
  position: relative;
`;

const HeroWrapper = styled.div`
  background: url(${props => heroImagesMobile[appConfig.appSource]});
  height: 100vh;
  position: fixed;
  width: 40%;

  background-repeat: no-repeat;
  background-size: 100% auto;
`;

const EditOrderSettingWrapper = styled.div`
  position: absolute;

  position: absolute; /*important*/
  top: 50%; /*position Y halfway in*/
  left: 50%; /*position X halfway in*/
  transform: translate(-50%, 50%); /*move it halfway back(x,y)*/

  max-width: 362px;
  min-width: 362px;

  min-height: 380px;

  @media (max-width: 786px) {
    padding: 0 20px;
  }
`;

const HomeFeedWrapper = styled.div`
  display: grid;
  grid-gap: 64px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 48px;
  @media (max-width: 1200px) {
    margin-top: 32px;
    overflow-x: unset;
    height: 100%;
  }
`;

const WelcomeMessage = styled(Title1)`
  color: ${props => props.theme.black};
  // margin: auto;
`;

const Home = ({customerProfile, isAuthenticated, loyalty}) => {
  //   return (
  //     <CompWrapper>
  //       <HeroWrapper>
  //         <HeroImage src={heroImages[appConfig.appSource]} alt="hero image" />
  //         <div style={{maxWidth: '740px', margin: 'auto'}}>
  //           <EditOrderConfigWrapper>
  //             <EditOrderConfigContainer isHomeOrderConfig />
  //           </EditOrderConfigWrapper>
  //         </div>
  //       </HeroWrapper>
  //       <Wrapper>
  //         {isAuthenticated && (
  //           <WelcomeMessage>
  //             Welcome Back, {customerProfile && customerProfile.first_name}
  //           </WelcomeMessage>
  //         )}
  //         {isAuthenticated &&
  //           loyalty && (
  //             <Fragment>
  //               {loyalty.loyalty_details.details.data.status === 'REGISTERED' ? (
  //                 <LoyaltyCardContainer />
  //               ) : (
  //                 <ActivateLoyaltyCardContainer />
  //               )}
  //             </Fragment>
  //           )}
  //         <HomeFeedContainer />
  //         {isAuthenticated && <OrderHistoryContainer hideIfNoOrders />}
  //       </Wrapper>
  //     </CompWrapper>
  //   );

  return (
    <Wrapper>
      <div style={{position: 'relative'}}>
        <HeroWrapper>
          <div style={{position: 'relative'}}>
            <EditOrderSettingWrapper>
              <OrderSettingSummaryContainer isHomeOrderConfig />
            </EditOrderSettingWrapper>
          </div>
        </HeroWrapper>
      </div>
      <HomeFeedWrapper>
        {isAuthenticated && (
          <WelcomeMessage id="HomeB-Message">
            Welcome Back, {customerProfile && customerProfile.first_name}
          </WelcomeMessage>
        )}
        {isAuthenticated ? (
          loyalty ? (
            appConfig.loyaltyType === 'givex' ? (
              <Fragment>
                {loyalty.loyalty_details.details.data.status ===
                'REGISTERED' ? (
                  <GiveXLoyaltyCardContainer id="HomeB-GiveXLoyaltyCardContainer" />
                ) : (
                  <ActivateLoyaltyCardContainer id="HomeB-ActivateLoyaltyCardContainer" />
                )}
              </Fragment>
            ) : (
              <InternalLoyaltyCardContainer id="HomeB-InternalLoyaltyCardContainer" />
            )
          ) : null
        ) : null}
        <HomeFeedContainer id="HomeB-HomeFeedContainer" />
        {isAuthenticated && <OrderHistoryContainer hideIfNoOrders />}
      </HomeFeedWrapper>
    </Wrapper>
  );
};

export default Home;
